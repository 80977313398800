<!--
 * @Descripttion:
 * @version:
 * @Author: 张圳
 * @Date: 2023-11-20 14:18:41
 * @LastEditors: guomengxia
 * @LastEditTime: 2024-06-11 17:10:39
-->
<template>
  <div class="container-warp">
    <div class="progress-box">
      <div class="base-right">
        <el-progress type="circle" :show-text="false" :percentage="percentageScore" :width="170" :stroke-width="16" stroke-linecap="round"/>
        <div class="textCenter">
          <div style="white-space: nowrap;">
            <span v-if="(basicInfo.markShowFlag&&basicInfo.markShowFlag==2)||(basicInfo.correctStatusValue=='已批阅')">{{basicInfo.testerTotalScore}}分</span>
            <span v-else>--</span>
          </div>
        </div>
      </div>
    </div>
    <!-- <el-form-item label="考试时长">{{basicInfo.examTime}}分钟</el-form-item>
                <el-form-item label="全部题目">{{basicInfo.questionNum}}</el-form-item>
                <el-form-item label="正确题目">{{basicInfo.trueNUm}}</el-form-item>
                <el-form-item label="错误题目">{{basicInfo.wrongNum}}</el-form-item>
                <el-form-item label="已批阅">{{basicInfo.reviewedNum}}</el-form-item>
                <el-form-item label="待批阅">{{basicInfo.unratedNum}}</el-form-item> -->
    <div class="info-box">
      <div class="info-box-title">{{basicInfo.correctStatusValue}}</div>
      <div class="info-box-time">试卷总分{{basicInfo.sumScore}}分</div>
      <div class="info-box-score"> 当前分数为系统自动批阅，人工批阅后将自动更新考试结果</div>
      <div class="info-box-detail">
        <div class="info-box-detail-item">
          <div>考试用时</div>
          <div>{{basicInfo.examTime}}分钟</div>
        </div>
        <div class="line-box"></div>
        <div class="info-box-detail-item">
          <div>总题目</div>
          <div>{{basicInfo.sumQuestionNum}}道</div>
        </div>
        <div class="line-box"></div>
        <div class="info-box-detail-item">
          <div>正确题目</div>
          <div>{{basicInfo.trueNUm}}道</div>
        </div>
        <div class="line-box"></div>
        <div class="info-box-detail-item">
          <div>错误题目</div>
          <div>{{basicInfo.wrongNum}}道</div>
        </div>
        <div class="line-box"></div>
        <div class="info-box-detail-item">
          <div>待批阅题目</div>
          <div>{{basicInfo.unratedNum}}道</div>
        </div>
        <div class="line-box"></div>
        <div class="info-box-detail-item">
          <div>未答题目</div>
          <div>{{basicInfo.unansweredNum}}道</div>
        </div>
      </div>
      <div style="margin-top:24px;">
        <el-button type="primary" @click="_viewPaperDeetail">查看试卷详情</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { getExamFinishDetailApi } from '@/api/examApi'
export default {
  data () {
    return {
      percentageScore: 0, // 分数百分比
      basicInfo: {}, // 基本信息
      examineFlag: null
    }
  },
  created () {
    if (this.$route.query.examInfoId && this.$route.query.employeeId) {
      this._getExamResultDetail(this.$route.query.examInfoId, this.$route.query.employeeId)
    }
  },
  mounted () {
  },
  // 页面销毁
  destroyed () {
  },
  methods: {
    // 查看试卷详情
    _viewPaperDeetail () {
      if (Number(this.examineFlag) !== 2) {
        this.$router.push({
          path: '/studentExamResultDetail',
          query: {
            examInfoId: this.$route.query.examInfoId,
            employeeId: this.$route.query.employeeId,
            typeValue: 'examResult'
          }
        })
      }
    },
    // 获取当前考试详情
    _getExamResultDetail (examInfoId, employeeId) {
      this.allTopicListCopy = []
      getExamFinishDetailApi({ examInfoId: examInfoId, testerId: employeeId }).then((res) => {
        if (res.code === 200) {
          this.basicInfo = res.data
          this.examineFlag = this.basicInfo.examineFlag
          this.percentageScore = (Number(this.basicInfo.testerTotalScore) / Number(this.basicInfo.sumScore)) * 100
          this.$forceUpdate()
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.base-right {
  position: relative;
  text-align: center;
  .textCenter {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    div {
      color: #0089FF;
      font-weight: 600;
      font-size: 28px;
      margin-bottom: 5px;
    }
  }
}
.container-warp {
  min-height: 800px;
  background-color: #ffffff;
  border-radius: 4px;
}
.progress-box{
  display: flex;
  justify-content: center;
  margin-top: 62px;
  /deep/.el-progress-circle{
    width: 150px !important;
    height: 150px !important;
  }
  /deep/.el-progress__text{
    font-size: 28px !important;
    color: #0089FF !important;
  }
}
.info-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    .info-box-title {
      margin-top: 26px;
      font-size: 20px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.9);
    }
    .info-box-time {
      margin-top: 8px;
      font-size: 16px;
      color: #0089ff;
    }
    .info-box-score {
      margin-top: 12px;
      font-size: 16px;
      color: #999999;
    }
    .info-box-detail {
      margin-top: 32px;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      width: 743px;
      height: 92px;
      background: linear-gradient(to bottom, #f0f8ff, #ffffff);
      border-radius: 8px;
      border: 1px solid #e7f4ff;
      .line-box {
        width: 1px;
        height: 38px;
        background-color: #d2ebff;
      }
      .info-box-detail-item {
        div {
          &:first-child {
            margin-bottom: 8px;
            font-size: 14px;
            color: #4d4d4d;
          }
          &:last-child {
            font-size: 18px;
            font-weight: 600;
            color: #333333;
          }
        }
      }
    }
  }
</style>
